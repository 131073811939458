<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Informes de Servicios</h4>
            <div class="small text-muted">Consulta de informes de servicios</div>
          </b-col>     
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">        
        <b-row v-if="arr.report">
          <b-col>
            <table class="table table-hover table-sm">
              <tbody>
                <tr v-if="arr.report.id">
                  <td class="reports-services-detail-title-table-custom align-middle">ID</td>
                  <td class="reports-services-detail-value-table-custom align-middle">
                    <b>{{this.arr.report.id}}</b>
                  </td>
                </tr>            
                <tr>
                  <td class="reports-services-detail-title-table-custom align-middle">Fecha</td>
                  <td class="reports-services-detail-value-table-custom align-middle">
                    {{moment(this.arr.report.date).format('DD MMMM YYYY')}}
                  </td>
                </tr>

                <tr v-if="arr.report.staff_id">
                  <td class="reports-services-detail-title-table-custom align-middle">Responsable</td>
                  <td class="reports-services-detail-value-table-custom align-middle">
                    <b-avatar :src="this.arr.report.staff.image"
                              v-if="this.arr.report.staff.image">
                    </b-avatar>
                    <b-avatar v-else                                                                         
                              variant="dark">
                    </b-avatar>                         
                    <b-icon icon="blank"></b-icon>
                    <b :title="'STAFF: ' + this.arr.report.staff.name">
                      {{this.arr.report.staff.name}}
                    </b>   
                  </td>
                </tr>                      

                <tr v-if="arr.report.customer">
                  <td class="reports-services-detail-title-table-custom align-middle">Cliente</td>
                  <td class="reports-services-detail-value-table-custom align-middle">                    
                    <b-avatar :src="this.arr.report.customer.image"
                              v-if="this.arr.report.customer.image">
                    </b-avatar>
                    <b-avatar v-else 
                              icon="building"                                            
                              variant="dark">
                    </b-avatar>     
                    <b-icon icon="blank"></b-icon>
                    <b>{{this.arr.report.customer.name}}</b>    
                    <b-icon icon="circle-fill" v-if="!arr.report.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                                                       
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="table table-hover table-sm">
              <tbody>
                <tr v-if="arr.report.observations">
                  <td class="reports-services-detail-title-table-custom align-middle">Observaciones</td>
                  <td class="reports-services-detail-value-table-custom align-middle">
                    {{this.arr.report.observations}}
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>          
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.INFORME_SERVICIOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'detailReport',
          elements: {}
        }, 
        reportID: 0,
        arr: {
          report: null
        }     
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.reportID = this.$route.params.reportID

      this.load()
      //this.validation()
    },
    methods: {       
      validation() {        
        var seller = Helper.getSeller()   

        if(seller) {                    
          var result = serviceAPI.mostrarVendedorWithCustomers(seller.id)

          result.then((response) => {        
            var objSeller = response.data 
            
            if(objSeller.customers) {
              var status = false
              objSeller.customers.forEach(element => {              
                if(this.arr.report.customers_id == element.id) {
                  status = true
                }   
              });
              if(!status) {
                this.$router.push({ name: 'Error403' })    
              }
            }                      
          })          
        }
      },              
      load() {
        var result = serviceAPI.mostrar(this.reportID)

        result.then((response) => {
          var data = response.data
          this.arr.report = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },    
    }    
  }
</script>
<style>
  .reports-services-detail-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 25%;
    text-align: right
  }
  .reports-services-detail-value-table-custom {
    padding-left: 5px;
  }
</style>